<template>
    <div
        class="slider mb-4"
        ref="slider"
        :style="{
            borderRadius: cssBorderRadius,
            overflow: 'hidden'
        }"
    >
        <n-carousel
            :dot-type="'line'"
            draggable
            autoplay
            :show-arrow="!smallScreenMode"
        >
            <image-item
                v-for="(imgPath, index) in sliderImageFiles"
                :key="index"
                :class="'carousel-img'"
                :source="imgPath"
                :alt="'Mykonos Diving - W-Diving - Kalafati Diving'"
                :style="sliderImageStyle"
            />
        </n-carousel>
        <div class="opener" :class="[smallScreenMode ? 'small' : 'py-1 not-small']">
            <div class="title">W-Diving</div>
            <div class="subtitle">@ Kalafati Bay - Mykonos - Greece - <b>30th Anniversary!</b></div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ImageItem from "../../components/ImageItem"

export default {
    components: {
        ImageItem
    },
    data() {
        return {
            sliderData: [],
            windowWidth: 0,
            currentImageWidth: 0,
            sliderImageStyle: {
                width: '100%',
                height: 'calc(100vw / 16 * 9)'
            }
        }
    },
    computed: {
        ...mapGetters( [ 'getSmallScreenMode' ] ),
        ...mapState( { guiTheme: 'themes' } ),
        cssBorderRadius() {
            return this.guiTheme.both.common.borderRadius
        },
        smallScreenMode() {
            return this.getSmallScreenMode
        },
        sliderImageFiles() {
            if ( this.$route.name === 'boat' ) {
                if ( this.currentImageWidth <= 640 )
                    return [
                        require( "../../assets/images/slider_boat/low/20210801_125306.jpg" ),
                        require( "../../assets/images/slider_boat/low/P1010301.jpg" ),
                        require( "../../assets/images/slider_boat/low/PICT0610.jpg" ),
                        require( "../../assets/images/slider_boat/low/PICT2625.jpg" )
                    ]
                else if ( this.currentImageWidth <= 1280 )
                    return [
                        require( "../../assets/images/slider_boat/middle/20210801_125306.jpg" ),
                        require( "../../assets/images/slider_boat/middle/P1010301.jpg" ),
                        require( "../../assets/images/slider_boat/middle/PICT0610.jpg" ),
                        require( "../../assets/images/slider_boat/middle/PICT2625.jpg" )
                    ]
                else
                    return [
                        require( "../../assets/images/slider_boat/high/20210801_125306.jpg" ),
                        require( "../../assets/images/slider_boat/high/P1010301.jpg" ),
                        require( "../../assets/images/slider_boat/high/PICT0610.jpg" ),
                        require( "../../assets/images/slider_boat/high/PICT2625.jpg" )
                    ]
            }
            else if ( this.$route.name === 'discover' ) {
                if ( this.currentImageWidth <= 640 )
                    return [
                        require( "../../assets/images/slider_discover/low/20180816_153426jpg.jpg" ),
                        require( "../../assets/images/slider_discover/low/20210821_155111.jpg" ),
                        require( "../../assets/images/slider_discover/low/IMG_8642c.jpg" )
                    ]
                else if ( this.currentImageWidth <= 1280 )
                    return [
                        require( "../../assets/images/slider_discover/middle/20180816_153426jpg.jpg" ),
                        require( "../../assets/images/slider_discover/middle/20210821_155111.jpg" ),
                        require( "../../assets/images/slider_discover/middle/IMG_8642c.jpg" )
                    ]
                else
                    return [
                        require( "../../assets/images/slider_discover/high/20180816_153426jpg.jpg" ),
                        require( "../../assets/images/slider_discover/high/20210821_155111.jpg" ),
                        require( "../../assets/images/slider_discover/high/IMG_8642c.jpg" )
                    ]
            }
            else if ( this.$route.name === 'equipment' ) {
                if ( this.currentImageWidth <= 640 )
                    return [
                        require( "../../assets/images/slider_equipment/low/20180420_141244.jpg" ),
                        require( "../../assets/images/slider_equipment/low/20181011_140543.jpg" ),
                        require( "../../assets/images/slider_equipment/low/20230328_121256.jpg" ),
                        require( "../../assets/images/slider_equipment/low/20230328_130626.jpg" )
                    ]
                else if ( this.currentImageWidth <= 1280 )
                    return [
                        require( "../../assets/images/slider_equipment/middle/20180420_141244.jpg" ),
                        require( "../../assets/images/slider_equipment/middle/20181011_140543.jpg" ),
                        require( "../../assets/images/slider_equipment/middle/20230328_121256.jpg" ),
                        require( "../../assets/images/slider_equipment/middle/20230328_130626.jpg" )
                    ]
                else
                    return [
                        require( "../../assets/images/slider_equipment/high/20180420_141244.jpg" ),
                        require( "../../assets/images/slider_equipment/high/20181011_140543.jpg" ),
                        require( "../../assets/images/slider_equipment/high/20230328_121256.jpg" ),
                        require( "../../assets/images/slider_equipment/high/20230328_130626.jpg" )
                    ]
            }
            else if ( this.$route.name === 'location' ) {
                if ( this.currentImageWidth <= 640 )
                    return [
                        require( "../../assets/images/slider_location/low/20120813_170934.jpg" ),
                        require( "../../assets/images/slider_location/low/20210619_144823.jpg" ),
                        require( "../../assets/images/slider_location/low/Kalafati_drone.jpg" )
                    ]
                else if ( this.currentImageWidth <= 1280 )
                    return [
                        require( "../../assets/images/slider_location/middle/20120813_170934.jpg" ),
                        require( "../../assets/images/slider_location/middle/20210619_144823.jpg" ),
                        require( "../../assets/images/slider_location/middle/Kalafati_drone.jpg" )
                    ]
                else
                    return [
                        require( "../../assets/images/slider_location/high/20120813_170934.jpg" ),
                        require( "../../assets/images/slider_location/high/20210619_144823.jpg" ),
                        require( "../../assets/images/slider_location/high/Kalafati_drone.jpg" )
                    ]
            }
            else if ( this.$route.name === 'padi' ) {
                if ( this.currentImageWidth <= 640 )
                    return [
                        require( "../../assets/images/slider_padi/low/20210730_104848.jpg" ),
                        require( "../../assets/images/slider_padi/low/PICT0333.jpg" ),
                        require( "../../assets/images/slider_padi/low/PICT1269.jpg" )
                    ]
                else if ( this.currentImageWidth <= 1280 )
                    return [
                        require( "../../assets/images/slider_padi/middle/20210730_104848.jpg" ),
                        require( "../../assets/images/slider_padi/middle/PICT0333.jpg" ),
                        require( "../../assets/images/slider_padi/middle/PICT1269.jpg" )
                    ]
                else
                    return [
                        require( "../../assets/images/slider_padi/high/20210730_104848.jpg" ),
                        require( "../../assets/images/slider_padi/high/PICT0333.jpg" ),
                        require( "../../assets/images/slider_padi/high/PICT1269.jpg" )
                    ]
            }
            else if ( this.$route.name === 'snorkle' ) {
                if ( this.currentImageWidth <= 640 )
                    return [
                        require( "../../assets/images/slider_snorkle/low/IMG_1311.jpg" ),
                        require( "../../assets/images/slider_snorkle/low/IMG_3778.jpg" ),
                        require( "../../assets/images/slider_snorkle/low/Snorkler.jpg" )
                    ]
                else if ( this.currentImageWidth <= 1280 )
                    return [
                        require( "../../assets/images/slider_snorkle/middle/IMG_1311.jpg" ),
                        require( "../../assets/images/slider_snorkle/middle/IMG_3778.jpg" ),
                        require( "../../assets/images/slider_snorkle/middle/Snorkler.jpg" )
                    ]
                else
                    return [
                        require( "../../assets/images/slider_snorkle/high/IMG_1311.jpg" ),
                        require( "../../assets/images/slider_snorkle/high/IMG_3778.jpg" ),
                        require( "../../assets/images/slider_snorkle/high/Snorkler.jpg" )
                    ]
            }
            else {
                if ( this.currentImageWidth <= 640 )
                    return [
                        require( "../../assets/images/header_slider/low/IMG_0467.jpg" ),
                        require( "../../assets/images/header_slider/low/IMG_0823.jpg" ),
                        require( "../../assets/images/header_slider/low/IMG_0884.jpg" ),
                        require( "../../assets/images/header_slider/low/IMG_1676.jpg" ),
                        require( "../../assets/images/header_slider/low/IMG_13062.jpg" ),
                        require( "../../assets/images/header_slider/low/IMG_8925.jpg" ),
                        require( "../../assets/images/header_slider/low/P1010302.jpg" ),
                        require( "../../assets/images/header_slider/low/PICT1216.jpg" ),
                        require( "../../assets/images/header_slider/low/Snorkler.jpg" )
                    ]
                else if ( this.currentImageWidth <= 1280 )
                    return [
                        require( "../../assets/images/header_slider/high/IMG_0467.jpg" ),
                        require( "../../assets/images/header_slider/high/IMG_0823.jpg" ),
                        require( "../../assets/images/header_slider/high/IMG_0884.jpg" ),
                        require( "../../assets/images/header_slider/high/IMG_1676.jpg" ),
                        require( "../../assets/images/header_slider/high/IMG_13062.jpg" ),
                        require( "../../assets/images/header_slider/high/IMG_8925.jpg" ),
                        require( "../../assets/images/header_slider/high/P1010302.jpg" ),
                        require( "../../assets/images/header_slider/high/PICT1216.jpg" ),
                        require( "../../assets/images/header_slider/high/Snorkler.jpg" )
                    ]
                else
                    return [
                        require( "../../assets/images/header_slider/middle/IMG_0467.jpg" ),
                        require( "../../assets/images/header_slider/middle/IMG_0823.jpg" ),
                        require( "../../assets/images/header_slider/middle/IMG_0884.jpg" ),
                        require( "../../assets/images/header_slider/middle/IMG_1676.jpg" ),
                        require( "../../assets/images/header_slider/middle/IMG_13062.jpg" ),
                        require( "../../assets/images/header_slider/middle/IMG_8925.jpg" ),
                        require( "../../assets/images/header_slider/middle/P1010302.jpg" ),
                        require( "../../assets/images/header_slider/middle/PICT1216.jpg" ),
                        require( "../../assets/images/header_slider/middle/Snorkler.jpg" )
                    ]
            }
        }
    },
    methods: {
        getSliderImageWidth() {
            if ( this.windowWidth <= 640 ) return 640;
            else if ( this.windowWidth <= 1280 ) return 1280;
            else return 1920;
        },
        setWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        checkNewWindowWidth() {
            this.setWindowWidth();
            const newWidth = this.getSliderImageWidth()

            if ( newWidth !== this.currentImageWidth )
                this.currentImageWidth = newWidth

            this.setWindowWidth()
        },
        setSliderImageStyle() {
            const slider = this.$refs.slider

            if ( slider ) {
                const sw = slider.clientWidth
                const sh = Math.ceil( ( sw / 16 * 9 ) )
                const newWidth = sw + 'px'
                const newHeight = this.windowWidth > 1280 ? 720 + 'px' : sh + 'px'

                this.sliderImageStyle = {
                    width: newWidth,
                    height: newHeight
                }

                slider.style.height = newHeight
            }
        }
    },
    beforeMount() {
        this.checkNewWindowWidth()
    },
    mounted() {
        window.sliderTImer = this.timer
        window.addEventListener( 'resize', this.checkNewWindowWidth )
        this.setSliderImageStyle()
    },
    watch: {
        windowWidth() {
            this.setSliderImageStyle()
        }
    }
}
</script>

<style scoped>
.slider {
    position: relative;
}

.carousel-img, .carousel-img :deep(img) {
    width: 100%;
    object-fit: cover;
}

.opener {
    position: absolute;
    color: var(--bs-white);
    border-radius: 15px;
}

.opener.small {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    bottom: 25px;
    background-color: unset;
    width: 100%;
}

.opener.not-small {
    left: 20px;
    bottom: 35px;
    max-width: calc(100% - 100px);
    width: auto;
    /*background-color: var(--bg-black-06);*/
}

.title {
    font-family: Teko, sans-serif;
    line-height: 1.5;
    font-size: 2rem;
}

.subtitle {
    font-size: 1rem;
}

.opener.small .title {
    font-size: 1rem;
}

.opener.small .subtitle {
    font-size: 0.75rem;
}
</style>