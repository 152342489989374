<template>
    <figure v-lazyload class="image-wrapper">
        <img
            class="img-fluid image-item"
            :data-url="source"
            :alt="alt"
        >
    </figure>
</template>

<script>
/**
 * @see https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/
 */
export default {
    props: {
        alt: { type: String, default: '' },
        source: { type: String, default: '' }
    }
}
</script>

<style scoped>

</style>